import React, { memo } from 'react';
import CustomChart from '../components/CustomChart/CustomChart';

const options = {
  maintainAspectRatio: false,
  responsive: true,
};

const normalizedData = ({ labels, datasets }) => {
  const result = {
    labels,
    datasets: [
      {
        data: datasets?.[0]?.data ?? [],
        backgroundColor: ['#2979ff', '#f9b903'],
      },
    ],
  };

  return result;
};
const CustomerSources = (props) => {
  return (
    <CustomChart
      type={'pie'}
      {...props}
      normalizedData={normalizedData}
      getOption={() => options}
    />
  );
};

export default memo(CustomerSources);
