import { t, Trans } from '@lingui/macro';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { updateSettings } from 'api/api';
import { AssetImage } from 'assets/Asset';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import chartsStyle from 'MUI_Kit/assets/jss/material-dashboard-pro-react/views/chartsStyle';
import Card from 'MUI_Kit/components/Card/Card';
import CardBody from 'MUI_Kit/components/Card/CardBody';
import CardHeader from 'MUI_Kit/components/Card/CardHeader';
import CardIcon from 'MUI_Kit/components/Card/CardIcon';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { defaults } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { compose } from 'redux';
import { Types } from 'redux/type';
import Routes from 'router/routes';
import { xPath } from 'testingClasses';
import DV from 'variables/DV';
import { handleApi } from 'variables/utils';
import { useApi } from '../../../../hook/utils';
import ListCharts from '../../Charts/ListCharts';
import '../../styles/CustomChart.css';
import { customTooltips } from '../../ToolTips/CustomToolTip';
import { CHART_DATE_FORMAT, chartsDateRangeSelector, getDateTime, useMaxYValue } from '../../utils';
import XLSX from 'xlsx';
import moment from 'moment';
import EmptyUI from "../../../../component/EmptyUI";

merge(defaults, {
  global: {
    responsive: true,
    defaultFontFamily: 'Open Sans',
  },
  scale: {
    gridLines: {
      drawOnChartArea: false,
    },
    scaleLabel: {
      display: true,
      fontFamily: 'Open Sans',
    },
  },
});

const CustomChart = (props) => {
  const dispatch = useDispatch();
  const {
    classes,
    code,
    getApi,
    normalizedData,
    getOption,
    inDetail = false,
    columns = [],
    canDelete,
  } = props;
  const chartRef = useMaxYValue();
  const { since, until } = useSelector(chartsDateRangeSelector());

  const {
    tooltip,
    title,
    hasDetail,
    color,
    icon,
    chartComponent: ChartComponent,
  } = useMemo(() => ListCharts()[code], [code]);

  const chartOption = useMemo(() => {
    const option = getOption(since, until);
    tooltip && (option.tooltips.custom = customTooltips(tooltip, code));
    return option;
  }, [getOption, since, until]);

  const graphs = useSelector((state) => state.common.graphs);
  const { loading, data, doFetch } = useApi();
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    (async () => {
      if (!since || !until) return;
      setError({});
      const { error, ...rest } = await doFetch(getApi({ since, until }));
      if (error) setError({ error, ...rest });
    })();
  }, [since, until]);

  useEffect(() => {
    if (normalizedData) setChartData(normalizedData(data));
    else setChartData(data);
    if (inDetail) setTableData(data?.datasets?.[0]?.data || []);
  }, [data]);

  const renderEmpty = () => {
    return (
      <div className={classNames('content', ' flex_center', xPath.stateView.empty)}>
        <img src={AssetImage.Empty} alt="empty" style={{ width: '50%', maxWidth: 300 }} />
        <div style={{ height: 30 }} />
        <div>{t`Chưa có dữ liệu`}</div>
        <div style={{ height: 20 }} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="content flex_center TEST__loading">
        <img src={AssetImage.Loading} style={{ width: '80%' }} alt="loading" />
        <div style={{ height: 30 }} />
        <div>{t` Đang tải dữ liệu...`}</div>
        <div style={{ height: 20 }} />
      </div>
    );
  };

  const renderError = () => {
    const { detail, error: errorMsg } = error;
    return (
      <div className="content flex_center TEST__loading">
        <img src={AssetImage.Error} style={{ width: '80%' }} alt="loading" />
        <div style={{ height: 30 }} />
        <div className="text-center">
          {!detail?.since && !detail?.until ? errorMsg : ''}
          {detail?.since && (
            <div>Biểu đồ này không thể hiển thị dữ liệu trước ngày {getDateTime(detail.since)}</div>
          )}
          {detail?.until && (
            <div>Biểu đồ này không thể hiển thị dữ liệu sau ngày {getDateTime(detail.until)}</div>
          )}
        </div>
        <div style={{ height: 20 }} />
      </div>
    );
  };

  const getChartHeight = () => {
    const minHeight = 350;
    if (inDetail) return undefined;
    return window.innerWidth < 600 ? minHeight : 'auto';
  };

  const renderChart = () => {
    if (loading) return renderLoading();
    if (!isEmpty(error)) return renderError();
    if (!chartData?.datasets?.some((dataset) => !isEmpty(dataset?.data))) return renderEmpty();

    return (
      <ChartComponent
        data={chartData}
        height={getChartHeight()}
        options={chartOption}
        ref={(ref) => chartRef?.(ref?.chartInstance)}
      />
    );
  };

  const deleteCharts = async () => {
    const oldGraphs = graphs;
    let indexInGraphs = graphs.findIndex((graph) => graph === code);
    if (indexInGraphs < 0) return;

    graphs.splice(indexInGraphs, 1);
    dispatch({ type: Types.SET_GRAPHS, payload: graphs });

    const { error } = await handleApi(updateSettings({ statistic_graphs: graphs }));
    if (error) {
      dispatch({ type: Types.SET_GRAPHS, payload: oldGraphs });
      DV.showNotify(error, 'danger');
    }
  };

  const renderTable = () => {
    return (
      <ReactTable
        previousText={t`Trang trước`}
        nextText={t`Trang sau`}
        pageText={t`Trang`}
        ofText={'/'}
        rowsText={t`Dòng`}
        noDataText={t`Không có dữ liệu`}
        loadingText={t`Đang tải dữ liệu...`}
        data={tableData}
        columns={columns}
        defaultPageSize={5}
        showPaginationTop={false}
        showPaginationBottom={tableData.length > 5}
        className="-striped -highlight"
        filterable={false}
        sortable={false}
      />
    );
  };

  const getSheetObjData = () => {
    const headerObj = columns.reduce((obj, item) => {
      if (item.accessor !== 'avatar') obj[item.accessor] = item.Header;
      if (item.accessor === 'name') obj[item.accessor] = item.Header || t`Tên`;
      return obj;
    }, {});

    const sheetData = [
      headerObj,
      ...chartData?.datasets[0]?.data?.map((item) =>
        Object.keys(item).reduce((obj, key) => {
          if (headerObj[key]) obj[key] = item[key];
          return obj;
        }, {}),
      ),
    ];

    console.log('getSheetObjData', {
      columns,
      headerObj,
      tableData,
      sheetData,
      chartOption,
      chartData: chartData.datasets,
    });

    return { header: Object.keys(headerObj), content: sheetData };
  };

  const getSheetArrayData = () => {
    const { datasets, labels } = chartData;

    const header = [' ', ...labels.map((item) => moment(item).format(CHART_DATE_FORMAT))];
    const content = datasets.map((item) => {
      const { label, data } = item;
      return [label, ...data];
    });

    const data = [header, ...content];
    if (data.some((item) => item.some((el) => typeof el === 'object'))) return null;
    return [header, ...content];
  };

  const handleExportChart = () => {
    const objData = getSheetObjData();
    const arrData = getSheetArrayData();

    const wb = XLSX.utils.book_new();
    const ws = arrData
      ? XLSX.utils.aoa_to_sheet(arrData)
      : XLSX.utils.json_to_sheet(objData.content, {
          header: objData.header,
          skipHeader: true,
        });
    const sheetName = title.replace(/[!@%^*()+=<>?\/,.:;'"&#\[\]~$_`\-{}|\\]/g, ' ').slice(-30);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(wb, `${DV.company.name}-Chart-${title}.xlsx`.replaceAll(' ', ''), {
      compression: true,
    });
  };

  const handleExportTable = () => {
    const headerObj = columns.reduce((obj, item) => {
      if (item.accessor !== 'avatar') obj[item.accessor] = item.Header;
      if (item.accessor === 'name') obj[item.accessor] = item.Header || t`Tên`;
      return obj;
    }, {});
    const sheetData = [
      headerObj,
      ...tableData.map((item) =>
        Object.keys(item).reduce((obj, key) => {
          if (headerObj[key]) obj[key] = item[key];
          return obj;
        }, {}),
      ),
    ];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(sheetData, {
      header: Object.keys(headerObj),
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, ws, title);

    XLSX.writeFile(wb, `${DV.company.name}-Table-${title}.xlsx`.replaceAll(' ', ''), {
      compression: true,
    });
  };

  console.log('chartData nè', chartData);

  if (!chartData) return null;
  const isDataEmpty = chartData?.datasets?.[0]?.data?.every(item => !item);
  return (
    <div style={{ height: '100%', paddingBottom: 50 }}>
      <Card>
        <CardHeader color={color} icon className="flex justify-between">
          <div style={{ display: 'flex' }}>
            <CardIcon color={color}>
              <i className={icon} style={{ fontSize: 28 }} />
            </CardIcon>
            <div className="flex items-end">
              <h4 className={classes.cardIconTitle}>{title}</h4>
              {!inDetail && hasDetail && (
                <Link
                  className="text-black underline ml-2 cursor-pointer"
                  to={`${Routes.statistic.path}/${code}`}>
                  <Trans>Chi tiết</Trans>
                </Link>
              )}
              {
                !isDataEmpty &&
                <div className="text-black underline ml-2 cursor-pointer" onClick={handleExportChart}>
                  <Trans>Xuất dữ liệu</Trans>
                </div>
              }
            </div>
          </div>
          {!inDetail && canDelete && (
            <IconButton aria-label="delete" size="small" onClick={deleteCharts}>
              <CloseIcon />
            </IconButton>
          )}
        </CardHeader>
        <CardBody className="flex_center">{isDataEmpty ? <EmptyUI/> : renderChart()}</CardBody>
      </Card>
      {inDetail && (
        <div className="w-full mt-[50px]">
          <Card>
            <CardHeader color={color} icon className="flex justify-between">
              <div style={{ display: 'flex' }}>
                <CardIcon color={'info'}>
                  <i className={'fad fa-table'} style={{ fontSize: 28 }} />
                </CardIcon>
                <div className="flex items-end">
                  <h4 className={classes.cardIconTitle}>{title}</h4>
                  <div
                    className="text-black underline ml-2 cursor-pointer"
                    onClick={handleExportTable}>
                    <Trans>Xuất dữ liệu</Trans>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody className="flex_center">{renderTable()}</CardBody>
          </Card>
        </div>
      )}
    </div>
  );
};

CustomChart.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  options: PropTypes.object,
  style: PropTypes.object,
  type: PropTypes.oneOf(['line', 'bar', 'pie', 'bubble']),
  inDetail: PropTypes.bool,
};

export default compose(withStyles(chartsStyle), memo)(CustomChart);
